<template>
  <div class="container mescroll-touch">
    <img class="menuIcon" src="@/assets/images/menu_fhxcl_icon@2x.png" @click="sidebarShow = true" />
    <div class="content">
      <div class="box" @click="toList('ssqkgr_whyf', 'ssqkgr|ssqkgr_whyf|', 'SSI的危害和预防')">
        <div class="left">
          <img class="icon" src="@/assets/images/whhyf_ssqkgr_icon@2x.png" />
        </div>
        <div class="right">
          <p class="title">SSI的危害和预防</p>
        </div>
      </div>
      <div class="box" @click="toList('ssqkgr_alj', 'ssqkgr|ssqkgr_alj|', 'SSI案例集')">
        <div class="left">
          <img class="icon1" src="@/assets/images/alj_ssqkgr_icon@2x.png" />
        </div>
        <div class="right">
          <p class="title">前车之鉴：<br />切口管理并发症案例集</p>
        </div>
      </div>
    </div>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import Sidebar from '../../components/sidebar.vue'

export default {
  name: 'SSIPrefecture',
  data() {
    return {
      sidebarShow: false
    }
  },
  mixins: [mixin],
  components: {
    Sidebar
  },
  methods: {
    popupClose(val) {
      this.sidebarShow = val;
    },
    toList(Code, TCode, name) {
      this.BuriedPoint(1, name, TCode, '/SSIList');
      this.$router.push({
        path: 'SSIList',
        query: {
          Code: Code,
          TCode: TCode
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px 65px;
  box-sizing: border-box;
  background-image: url('../../assets/images/ssqkgr_bg_pic@3x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #F9F9F9;
  .menuIcon {
    position: absolute;
    top: 25px;
    left: 22px;
    width: 30px;
    height: 30px;
  }
  .content {
    margin-top: 110%;
    .box {
      display: flex;
      padding: 10px 0;
      background-color: #FFFFFF;
      border-radius: 10px;
      .left {
        display: flex;
        align-items: center;
        position: relative;
        width: 66px;
        padding: 10px 0;
        .icon {
          display: block;
          width: 25px;
          margin: 0 auto;
        }
        .icon1 {
          display: block;
          width: 22px;
          margin: 0 auto;
        }
      }
      .left::after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(to bottom, #9E8FD8, #FCEB8F);
      }
      .right {
        flex: 1;
        padding: 10px 8px;
        .title {
          font-size: 16px;
          color: #5C4DA7;
          font-weight: bold;
        }
      }
    }
    .box:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
</style>